import React from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useCanvasCourses } from 'controllers/react-query/canvasHooks';
import { Link as RouterLink } from 'react-router-dom';
import { AddLink } from '@mui/icons-material';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useModal } from 'components/utils/ModalContext';

export type Props = {
  onSelect: (courseId: string) => void;
};

export function CanvasListCourses({ onSelect }: Props) {
  const { data, isPending, isError } = useCanvasCourses();
  const { closeModal } = useModal();

  if (isPending) {
    return <LoadingIndicator message='Fetching Canvas courses...' />;
  }

  if (isError) {
    return (
      <>
        <Typography>
          Would you like to link your Canvas account to Kyron? This will let you enhance your existing Canvas courses
          with Kyron interactions.
        </Typography>
        <RouterLink to='/auth/canvas' target='_blank'>
          <Button startIcon={<AddLink />}>Link Canvas Account</Button>
        </RouterLink>
        <Typography>Please refresh this page after connecting your Canvas account.</Typography>
      </>
    );
  }

  if (data?.error === 'configure_canvas_instance') {
    return (
      <>
        <Typography>
          It looks like you haven&apos;t configured Kyron to work with Canvas yet. Would you like to do that now?
        </Typography>
        <RouterLink to='/studio/settings/integrations'>
          <Button onClick={closeModal}>Configure Canvas</Button>
        </RouterLink>
        <Typography>Note: You need to be an admin on your Canvas instance to complete this step.</Typography>
      </>
    );
  }

  return (
    <Table sx={{ typography: 'bodyMedium' }}>
      <TableHead>
        <TableRow>
          <TableCell>Course Name</TableCell>
          <TableCell>Course Code</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.courses?.map(course => (
          <TableRow>
            <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              <Button variant='text' onClick={() => onSelect(course.id)}>
                {course.name}
              </Button>
            </TableCell>
            <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {course.course_code}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
