import React from 'react';
import { Stack, Typography, Container, Button, Radio, MenuItem, FormControlLabel, Box } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { HookTextField } from 'components/FormWrappers/HookTextField';
import { HookSelect } from 'components/FormWrappers/HookSelect';
import { HookRadioGroup } from 'components/FormWrappers/HookRadioGroup';
import { useRecommendedLessonValues } from 'controllers/react-query/canvasHooks';
import { CanvasCourseSelector } from 'components/Canvas/CanvasCourseSelector';
import { useSnackbar } from 'notistack';
import { GenerateQuestionsPayload } from 'controllers/react-query/lessonGenerationHooks';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useModal } from 'components/utils/ModalContext';

type FormValues = {
  activityTemplate: string;
  auxContentSignedIds: string[];
  lessonObjective: string;
  videoIntroduction: string;
};

const activityTemplates = [
  { label: 'Guided discussion', value: 'exploratory' },
  { label: 'Scenario-based discussion', value: 'scenario' },
  { label: 'Reflective conversation', value: 'reflection' },
];

const defaultValues: FormValues = {
  activityTemplate: activityTemplates[0].value,
  auxContentSignedIds: [],
  lessonObjective: '',
  videoIntroduction: '',
};

const videoIntroductionOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export function DiscussionBuilder() {
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const { mutateAsync: getRecommendedLessonValues, isPending: canvasLoading } = useRecommendedLessonValues();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useModal();

  // This function is not yet implemented
  const onSubmit: SubmitHandler<FormValues> = () => {};

  function getObjectiveFromCanvas(courseId: string, moduleId: string) {
    closeModal();
    getRecommendedLessonValues({ courseId, moduleId })
      .then((data: Partial<GenerateQuestionsPayload>) => {
        const objective = data.lessonObjective || '';
        setValue('lessonObjective', objective);
      })
      .catch((error: Error) => {
        console.error('Failed to fill in lesson create form from Canvas course', error);
        enqueueSnackbar('Could not read Canvas course. Please try again later.', { variant: 'error' });
      });
  }

  function handleCanvasSelection() {
    openModal({
      id: 'canvas-course-selector-modal',
      title: 'Select your Canvas content',
      content: (
        <Box sx={{ pb: 4, minHeight: 400 }}>
          {canvasLoading && <LoadingIndicator message='Reading course info from Canvas...' />}
          <CanvasCourseSelector onSelect={getObjectiveFromCanvas} />
        </Box>
      ),
    });
  }

  return (
    <Container maxWidth='sm' sx={{ mb: 2 }}>
      <Stack gap={10}>
        <Typography variant='titleLarge' alignSelf='center'>
          Create a discussion
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} data-testid='discussion-builder-form'>
          <Stack gap={10}>
            <Stack gap={2}>
              <Typography variant='bodyLarge' component='label' id='activity-template-label'>
                1. What kind of discussion would you like?
              </Typography>
              <HookSelect
                name='activityTemplate'
                control={control}
                id='activity-template'
                labelId='activity-template-label'
              >
                {activityTemplates.map(template => (
                  <MenuItem key={template.value} value={template.value}>
                    {template.label}
                  </MenuItem>
                ))}
              </HookSelect>
            </Stack>

            <Stack gap={2}>
              <Typography variant='bodyLarge'>
                2. Upload any existing learning materials and we&apos;ll generate a discussion for you based on that
                material.
              </Typography>
              <Stack direction={{ xs: 'column', sm: 'row' }} gap={3} alignSelf='center'>
                {/* Note: These buttons are not yet a part of the form */}

                <Button variant='contained' disabled>
                  Upload learning materials
                </Button>
                <Button
                  variant='outlined'
                  onClick={handleCanvasSelection}
                  disabled={canvasLoading}
                  startIcon={canvasLoading ? <LoadingIndicator /> : undefined}
                >
                  Choose from Canvas
                </Button>
              </Stack>
              <Typography variant='bodyLarge' component='label' htmlFor='lesson-objective'>
                Or tell us what you&apos;d like your learners to discuss.
              </Typography>
              <HookTextField
                name='lessonObjective'
                control={control}
                multiline
                minRows={4}
                maxRows={4}
                id='lesson-objective'
              />
            </Stack>

            <Stack gap={2}>
              <Typography variant='bodyLarge' component='label' htmlFor='video-introduction'>
                3. Include a short video introduction before the discussion to provide context?
              </Typography>
              <HookRadioGroup name='videoIntroduction' control={control} id='video-introduction'>
                {videoIntroductionOptions.map(option => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio sx={{ mr: 1 }} />}
                    label={option.label}
                    sx={{ mt: 1 }}
                  />
                ))}
              </HookRadioGroup>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
